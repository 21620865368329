function formatNumber(value) {
    let number = Number(value)

    // Разбиваем число на целую и дробную части
    let [integerPart, decimalPart] = number.toFixed(2).split(".");

    // Форматируем целую часть: разделяем тысячи пробелами
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // Если дробная часть равна нулю, не отображаем её
    if (parseInt(decimalPart, 10) === 0) {
        return integerPart;
    }

    // Возвращаем число с форматированной дробной частью
    return `${integerPart}.${decimalPart}`;
}

export default formatNumber;
