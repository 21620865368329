<template>
    <div class="timer" :class="{ 'alert': isAlert }">
        <div class="clock-icon"></div>
        <span class="timer_value">{{ formattedTime }}</span>
    </div>
</template>
  
  <script>
  export default {
    name: 'PaymentTimer',
    props: {
      endTime: {
        type: String,
        default: null
      },
      countdownSeconds: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        interval: null,
        timeRemaining: 0 // Остаток времени в секундах
      };
    },
    computed: {
      formattedTime() {
        const minutes = Math.floor(this.timeRemaining / 60);
        const seconds = this.timeRemaining % 60;
        return `${this.padTime(minutes)}:${this.padTime(seconds)}`;
      },
      isAlert() {
        // Возвращает true, если осталось меньше двух минут (120 секунд)
        return this.timeRemaining < 120;
      }
    },
    methods: {
      padTime(time) {
        return time < 10 ? `0${time}` : time;
      },
      updateTimeRemaining() {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else if (this.timeRemaining === 0) {
          clearInterval(this.interval);
          this.$emit('timeout');
        }
      }
    },
    mounted() {
      if (this.countdownSeconds !== null) {
        this.timeRemaining = this.countdownSeconds;
      } else if (this.endTime !== null) {
        const now = new Date();
        const endTime = new Date(this.endTime.replace(/-/g, '/'));
        this.timeRemaining = Math.max(0, Math.floor((endTime - now) / 1000));
      }
      this.interval = setInterval(this.updateTimeRemaining, 1000);
    },
    beforeUnmount() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  }
  </script>
  
  <style scoped>
  </style>
  