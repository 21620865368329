<template>
  <div class="modal-overlay" @click="$emit('input', false)">
    <div class="modal" @click.stop>
      <slot ></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalWindow",
}
</script>

<style scoped>
  .modal {
    max-width: 510px;
  }
</style>