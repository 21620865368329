<template>
    <div class="copy-container" @click="copyToClipboard">
      <img src="../assets/img/copy.svg" alt="Copy" class="copy-icon" />
      <div v-if="showTooltip" class="tooltip">Скопировано</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CopyButton',
    props: {
      textToCopy: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        showTooltip: false,
        tooltipTimeout: null
      };
    },
    methods: {
      copyToClipboard() {
        // Копируем текст в буфер обмена
        navigator.clipboard.writeText(this.textToCopy).then(() => {
          // Показываем тултип "Скопировано"
          this.showTooltip = true;
  
          // Устанавливаем таймер для скрытия тултипа через 3 секунды
          if (this.tooltipTimeout) {
            clearTimeout(this.tooltipTimeout);
          }
          this.tooltipTimeout = setTimeout(() => {
            this.showTooltip = false;
          }, 3000);
        }).catch(err => {
          console.error('Ошибка при копировании текста: ', err);
        });
      }
    }
  }
  </script>
  
  <style scoped>
  .copy-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .copy-icon {
    width: 24px;
    height: 24px;
  }
  
  .tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 12px;
    opacity: 0.8;
  }
  </style>
  