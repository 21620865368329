<template>
  <div class="error">
    <div class="container">
      <img src="@/assets/img/cancel.svg" width="36" height="36" alt="error" />
      <h1 class="title">Платеж не найден</h1>
      <div class="description">Неверный ID платежа</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorScreen',
};
</script>

<style scoped>
.error {
  flex: 1;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
}

.description {
  font-size: 14px;
  color: #676560;
  font-weight: 500;
  line-height: 140%;
}
</style>
