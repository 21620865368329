<template>
  <div v-if="isMobile">
    <div v-if="bank != 'other'" class="container">
      <div class="badge badge_success">
        Пополнение на {{ formatNumber(payment.fiat_amount) }} ₽
      </div>
      <h1>
        {{ bank == 'sberbank' ? 'Оплата со Сбербанка' : 'Оплата с Т-Банка' }}
      </h1>

      <div class="block block_amount">
        <div class="title">
          {{
            bank == 'sberbank'
              ? 'Скопируйте сумму пополнения'
              : 'Сумма пополнения'
          }}
        </div>
        <div class="value">
          {{ formatNumber(payment.fiat_amount) }} ₽
          <CopyButton :textToCopy="payment.fiat_amount" />
        </div>
      </div>

      <div class="block block_click">
        <p v-if="bank == 'sberbank'" class="title">
          Нажмите на кнопку ниже, затем <span>вставьте сумму</span> пополнения
        </p>
        <p v-if="bank == 'tinkoff'" class="title">
          Нажмите на кнопку ниже, чтобы
          <span>перейти в Т-Банк</span> для&nbsp;оплаты
        </p>
        <CustomButton
          :href="href"
          :payment="payment"
          :class="bank"
          label="Оплатить"
          :iconType="bank"
          iconPosition="right"
        />
      </div>

      <button id="markCancel" @click="isShowCancel = true">
        <img src="@/assets/img/cancel.svg" />Отменить платеж
      </button>
    </div>

    <div v-if="bank == 'other'" class="container">
      <div class="badge badge_success">
        Пополнение на {{ formatNumber(payment.fiat_amount) }} ₽
      </div>
      <h1>Переведите по&nbsp;указанным реквизитам</h1>

      <div class="block block_amount">
        <div class="title">
          {{
            bank == 'sberbank'
              ? 'Скопируйте сумму пополнения'
              : 'Сумма пополнения'
          }}
        </div>
        <div class="value">
          {{ formatNumber(payment.fiat_amount) }} ₽
          <CopyButton :textToCopy="payment.fiat_amount" />
        </div>
      </div>

      <NoBank
        v-if="bank == 'other'"
        @openModal="openModal"
        @closeModal="closeModal"
        @markPaid="markPaid"
        :payment="payment"
      />

      <button id="markCancel" @click="isShowCancel = true">
        <img src="@/assets/img/cancel.svg" />Отменить платеж
      </button>
    </div>
  </div>
  <QRCodeSection
    v-else
    :payment="payment"
    :handleCancel="handleCancel"
  ></QRCodeSection>

  <!-- @click="$emit('markCancel')" -->

  <PaymentSpoiler
    v-if="bank != 'other'"
    @openModal="openModal"
    @closeModal="closeModal"
    @markPaid="markPaid"
    :payment="payment"
  />

  <ModalWindow
    :value="isShowCancel"
    @input="isShowCancel = $event"
    v-if="isShowCancel"
  >
    <img src="../assets/img/cancel-big.svg" alt="" />
    <h2 class="mt-20"><b>Отмена платежа</b></h2>
    <p class="mt-10" style="font-size: 16px">Подтвердите действие</p>
    <CustomButton
      component="button"
      label="Отменить"
      @click="$emit('markCancel')"
      class="mt-30 red small"
    />
    <CustomButton
      component="button"
      label="Вернуться к оплате"
      @click="isShowCancel = false"
      class="mt-15 gray small"
    />
  </ModalWindow>
</template>

<script>
import PaymentSpoiler from './PaymentSpoiler.vue';
import CopyButton from './CopyButton.vue';
import QRCodeSection from './QRCodeSection.vue';
import CustomButton from './CustomButton.vue';
import '../assets/styles/PaymentScreen.css';

import ModalWindow from './ModalWindow.vue';

import isMobile from '../assets/utils/isMobile.js';
import formatNumber from '../assets/utils/formatNumber.js';
import NoBank from './NoBank.vue';

export default {
  data() {
    return {
      isMobile: isMobile(),
      isShowCancel: false,
    };
  },
  name: 'PaymentScreen',
  components: {
    PaymentSpoiler,
    CopyButton,
    CustomButton,
    ModalWindow,
    NoBank,
    QRCodeSection,
  },

  props: {
    payment: Object,
  },
  mounted() {},
  computed: {
    href() {
      const bank = {
        tinkoff: 1,
        sberbank: 2,
      }[this.payment.source_transaction.payeer_bank_name];
      return `https://3ds.payeveryday.net/dl/${this.payment.uuid}${bank}?auto=1`;
    },
    bank() {
      return this.payment.source_transaction.payeer_bank_name;
    },
  },
  methods: {
    markPaid() {
      this.$emit('markPaid');
    },
    handleCancel() {
      this.isShowCancel = true;
    },
    formatNumber: formatNumber,
  },
};
</script>

<style scoped>
#markCancel {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 500;
  color: #ff3737;
  filter: grayscale(1) brightness(1.7);
  transition: 0.3s;
}

#markCancel img {
  filter: grayscale(1) brightness(1.2);
  transition: 0.3s;
}

#markCancel:hover,
#markCancel:hover img {
  filter: none;
}
</style>
