<template>
    <div class="step">
      <div class="step_title">
        <div class="number">{{ step.number }}</div>
        <p v-html="step.text"></p>
      </div>
      <div v-if="step.copyValue" class="copy-block">
        <span class="copy-value">{{ step.formattedValue }}</span>
        <CopyButton :textToCopy="step.copyValue" />
      </div>
    </div>
  </template>
  
  <script>
  import CopyButton from './CopyButton.vue';
  
  export default {
    name: 'PaymentStep',
    components: {
      CopyButton
    },
    props: {
      step: {
        type: Object,
        required: true
      }
    }
  }
  </script>

  