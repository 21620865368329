<template>
  <div class="container">
    <div class="badge badge_success">
      Пополнение на {{ formatNumber(payment.fiat_amount) }} ₽
    </div>

    <h1>
      {{ bank == 'sberbank' ? 'Оплата со Сбербанка' : 'Оплата с Т-Банка' }}
    </h1>

    <div class="qr">
      <p class="description">Отсканируйте QR-код, чтобы оплатить с телефона</p>
      <qrcode-vue
        class="qrcode"
        :value="link"
        size="200"
        level="H"
        render-as="svg"
        :background="'#F9F9F9'"
        :foreground="'#373230'"
      />
      <p class="description bottom">Или скопируйте ссылку</p>
      <div class="copy-wrapper">
        <span class="copy-item" :title="link">{{ link }}</span>
        <CopyButton :textToCopy="link" />
      </div>
    </div>
    <button id="markCancel" @click="handleCancel">
      <img src="@/assets/img/cancel.svg" />Отменить платеж
    </button>
  </div>
</template>

<script>
import formatNumber from '../assets/utils/formatNumber.js';
import QrcodeVue from 'qrcode.vue';
import CopyButton from './CopyButton.vue';

export default {
  name: 'QRCodeSection',
  props: {
    payment: Object,
    handleCancel: Function,
  },
  components: {
    QrcodeVue,
    CopyButton,
  },
  data() {
    return {
      link: window.location.href,
    };
  },
  computed: {
    bank() {
      return this.payment.source_transaction.payeer_bank_name;
    },
  },
  methods: {
    formatNumber: formatNumber,
  },
};
</script>

<style scoped>
.qr {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.description {
  color: #676560;
  font-weight: 500;
  line-height: 140%;
}

.description.bottom {
  margin-bottom: 10px;
}

.qrcode {
  margin: 20px 0;
}

.copy-wrapper {
  width: 100%;
  padding: 10px 10px 10px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #ffffff;
  border-radius: 12px;
}

.copy-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#markCancel {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #b8b7b5;
  font-size: 14px;
}

#markCancel img {
  filter: grayscale(1) brightness(1.8);
  transition: 0.3s;
}

#markCancel:hover,
#markCancel:hover img {
  filter: none;
}
</style>
