function getAudioFingerprint() {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const audioContext = new (window.OfflineAudioContext ||
      window.webkitOfflineAudioContext)(1, 44100, 44100);

    const oscillator = audioContext.createOscillator();
    oscillator.type = 'triangle';
    oscillator.frequency.setValueAtTime(10000, audioContext.currentTime);

    const gainNode = audioContext.createGain();
    gainNode.gain.setValueAtTime(0.5, audioContext.currentTime);

    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);

    oscillator.start(0);
    audioContext
      .startRendering()
      .then((buffer) => {
        const output = buffer.getChannelData(0);

        let hash = 0;
        for (let i = 0; i < output.length; i++) {
          hash += output[i] ** 2;
        }

        resolve(hash.toString());
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function hashString(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash |= 0;
  }
  return hash;
}

function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function getPlugins() {
  return Array.from(navigator.plugins)
    .map((plugin) => plugin.name)
    .join(', ');
}

function getWebGLInfo() {
  try {
    const canvas = document.createElement('canvas');
    const gl =
      canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (!gl) return 'WebGL not supported';

    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');

    if (debugInfo) {
      const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
      const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);

      return { vendor, renderer };
    }

    return 'WebGL not supported';
  } catch (e) {
    return 'WebGL not supported';
  }
}

function getCanvasFingerprint() {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.textBaseline = 'top';
    ctx.font = '14px Arial';
    ctx.fillStyle = '#f60';
    ctx.fillRect(0, 0, 150, 50);
    ctx.fillStyle = '#069';
    ctx.fillText('Hello, world!', 2, 15);
    ctx.strokeStyle = 'red';
    ctx.strokeRect(5, 5, 140, 30);
  }

  return canvas.toDataURL();
}

function getLanguages() {
  return navigator.languages
    ? navigator.languages.join(', ')
    : navigator.language;
}

function getColorScheme() {
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
}

// eslint-disable-next-line func-names
export const getFp = async () => {
  const webgl = getWebGLInfo();
  const data = {
    platform: navigator.platform,
    timezone: getTimezone(),
    languages: getLanguages(),
    color_scheme: getColorScheme(),
    plugins: getPlugins(),
    webgl_vendor: typeof webgl === 'string' ? webgl : webgl.vendor,
    webgl_render: typeof webgl === 'string' ? webgl : webgl.renderer,
    canvas_fp: hashString(getCanvasFingerprint()),
    screen: `${window.screen.width}x${window.screen.height}`,
    color_depth: window.screen.colorDepth,
    memory: navigator.deviceMemory || 0,
    concurency: navigator.hardwareConcurrency || 0,
    touch: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
    audio_fp: await getAudioFingerprint(),
  };

  const jsonString = JSON.stringify(data);
  const base64String = btoa(jsonString);

  return base64String;
};
