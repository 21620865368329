export default function formatRequisites(requisitesNumber, requisitesType) {
    if (requisitesType === 'sbp') {
      // Форматирование телефонного номера
      return requisitesNumber.replace(/^(\+7)(\d{3})(\d{3})(\d{4})$/, '$1 $2 $3 $4');
    } else if (requisitesType === 'card') {
      // Форматирование номера карты
      return requisitesNumber.replace(/(\d{4})(?=\d)/g, '$1 ').trim();
    } else if (requisitesType === 'account_number') {
      // Форматирование номера счета
      return requisitesNumber.replace(/^(\d{5})(\d{3})(\d)(\d{4})(\d{7})$/, '$1 $2 $3 $4 $5');
    } else {
      return requisitesNumber; // Возврат без изменений, если тип не соответствует
    }
  }
  