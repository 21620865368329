<template>
<div v-if="payment.state === 'successed'" class="container">
    <div class="badge badge_success xl">+ {{ formatNumber(payment.fiat_amount) }} ₽</div>
    <div>
        <h1>Успешно оплачено</h1>
        <p class="mt-10">Средства зачислены на ваш счет</p>
    </div>
    <CustomButton v-if="payment.source_transaction.redirect_url" :href="payment.source_transaction.redirect_url" label="Вернуться в магазин" class="gray small" />
</div>
<div v-else-if="payment.state === 'canceled'" class="container">
    <div class="badge badge_error xl">{{ formatNumber(payment.fiat_amount) }} ₽</div>
    <div>
        <h1>Платеж отменен</h1>
        <p class="mt-10">Вы отменили этот платеж</p>
    </div>
    <div style="width: 100%;">
        <CustomButton v-if="payment.source_transaction.redirect_url" :href="payment.source_transaction.redirect_url" label="Вернуться в магазин" class="gray small" />
        <CustomButton component="button" label="Оспорить решение" @click="isShowDispute = true" class="accent small mt-15" />
    </div>
</div>
<div v-else-if="payment.state === 'expired'" class="container">
    <div class="badge badge_error xl">{{ formatNumber(payment.fiat_amount) }} ₽</div>
    <div>
        <h1>Оплата не прошла</h1>
        <p class="mt-10">Истекло время на оплату</p>
    </div>
    <div style="width: 100%;">
        <CustomButton v-if="payment.source_transaction.redirect_url" :href="payment.source_transaction.redirect_url" label="Вернуться в магазин" class="gray small" />
        <CustomButton component="button" label="Оспорить решение" @click="isShowDispute = true" class="accent small mt-15" />
    </div>
</div>

<div class="container">
    <h2 style="text-align: left; width: 100%;">Информация о платеже</h2>
    <div class="status-block-description">
        <TableElem v-for="elem in elemData" :key="elem.id" :elem="elem" />
    </div>
</div>

<ModalWindow :value="isShowDispute" @input="isShowDispute = $event" v-if="isShowDispute">
    <div class="dispute">
        <form>
            <div class="form-title">Оспаривание решения</div>
            <div class="form-description">Заполните указанные поля</div>
            <div class="form-row">
                <label class="form-label">ID платежа</label>
                <input type="text" v-model="form.id" disabled />
            </div>

            <div class="form-row">
                <label class="form-label" for="amount">Какую сумму вы отправили?</label>
                <input name="amount" type="number" v-model="form.fiat_amount" />
            </div>

            <div class="form-row">
                <label class="form-label">Приложите чек(-и) о переводе</label>
                <label class="uploader">
                    <div>Загрузите файл(-ы)</div>
                    <input type="file" v-on:change="changeFile" />
                </label>
            </div>
            <button class="form-submit" @click.prevent.stop="submit">
                <span> Отправить </span>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5156_1913)">
                        <path d="M0.128976 2.78095C-0.0137971 2.39367 -0.0368622 1.97242 0.0627744 1.57186C0.162411 1.17131 0.380148 0.809957 0.687726 0.534701C0.992813 0.258513 1.37367 0.0800969 1.78115 0.0224778C2.18863 -0.0351413 2.60402 0.0306813 2.97373 0.211451L16.5412 6.54445C16.8928 6.70675 17.2052 6.94311 17.457 7.23735C17.7087 7.53158 17.894 7.87674 18 8.2492H3.03598L0.171726 2.8762C0.155955 2.84516 0.141689 2.81337 0.128976 2.78095ZM3.04648 9.74995L0.231726 15.1312C0.216085 15.1607 0.202551 15.1913 0.191226 15.2227C0.0490316 15.6101 0.0265622 16.0312 0.126735 16.4315C0.226907 16.8318 0.445095 17.1927 0.752976 17.4675C1.13196 17.8087 1.62373 17.9979 2.13373 17.9985C2.44048 17.9985 2.74798 17.9295 3.03448 17.7892L16.5427 11.4607C16.8949 11.2979 17.2077 11.0608 17.4595 10.7656C17.7113 10.4704 17.8962 10.1242 18.0015 9.7507H3.04648V9.74995Z" fill="#B8B7B5" />
                    </g>
                    <defs>
                        <clipPath id="clip0_5156_1913">
                            <rect width="18" height="18" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>
        </form>
    </div>
</ModalWindow>
</template>

<script>
import axios from "axios";
import ModalWindow from './ModalWindow.vue';
import CustomButton from './CustomButton.vue';
import TableElem from './TableElem.vue';
import formatNumber from '../assets/utils/formatNumber.js';
import formatRequisites from '../assets/utils/formatRequisites.js';

export default {
    name: "PaymentResult",
    components: {
        ModalWindow,
        CustomButton,
        TableElem
    },
    props: {
        payment: Object,
    },
    data() {
        const arr = window.location.pathname.split('/');
        return {
            form: {
                id: arr[arr.length-1]
            },
            isShowDispute: false,
        };
    },
    computed: {
        elemData() {
            return [
            {
                id: 1,
                title: 'ID',
                value: this.payment.source_transaction.id,
                copyValue: this.payment.source_transaction.id
            },
            {
                id: 2,
                title: 'Сумма',
                value: formatNumber(this.payment.fiat_amount) + ' ₽',
                copyValue: this.payment.fiat_amount
            },
            {
                id: 3,
                title: 'Реквизиты',
                value: formatRequisites(this.payment.source_transaction.requisities.number, this.payment.source_transaction.payment_method),
                copyValue: this.payment.source_transaction.requisities.number
            },
            {
                id: 4,
                title: 'Получатель',
                value: this.payment.source_transaction.requisities.card_holder
                
            },
            {
                id: 5,
                title: 'Способ оплаты',
                value: {sbp: 'СБП', card: 'Карта', account_number: 'Номер счета'}[this.payment.source_transaction.payment_method],
            }]
        }
    },
    methods: {
        async submit() {
            const formData = new FormData();
            formData.append("uuid", this.form.id);
            formData.append("amount", this.form.fiat_amount);
            formData.append("proof_image", this.files[0]);

            const {
                data
            } = await axios.post(
                "https://api.payeveryday.net/payer/v1/p2p_disputes",
                formData
            );
            if (data.data.state == "opened") {
                alert("Диспут принят");
            }
        },
        changeFile(e) {
            this.files = e.target.files || e.dataTransfer.files;
        },
        formatNumber: formatNumber,
        openModal(name) {
            this.modalName = name;
            this.isModalOpened = true;
            document.body.classList.add('no-scroll');
        },
        closeModal() {
            this.isModalOpened = false;
            this.modalName = null;
            document.body.classList.remove('no-scroll');
        }
    },

};
</script>

<style scoped>
.status-block-title {
    color: #373230;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    margin: 10px 0;
}

.status-block-description {
    width: 100%;
    color: #676560;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.status-block {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: #FFF;
    min-width: 360px;
    margin-bottom: 20px;
}

.btn {
    display: flex;
    height: 56px;
    padding: 20px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}

.btn-gray {
    background: #F0F0F0;
    color: #373230;
}

.btn-orange {
    background: rgba(255, 78, 15, 0.10);
    color: #FF4E0F;
}

.form-title {
    color: #373230;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;
}

.form-description {
    color: #676560;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 30px;
}

.form-label {
    color: #676560;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
}

.form-row {
    margin-bottom: 20px;
}

input[type="file"] {
    opacity: 0;
    width: 0;
    height: 0;
}

input {
    color: #373230;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    border-radius: 12px;
    border: 1px #f0f0f0 solid;
    display: block;
    width: 100%;
    padding: 10px 12px;
}

input:disabled {
    border-radius: 12px;
    border: 1px #f0f0f0 solid;
    background: #f9f9f9;
}

.uploader {
    border-radius: 20px;
    border: 1px #f0f0f0;
    background: #f9f9f9;
    display: flex;
    width: 315px;
    height: 96px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    color: #b8b7b5;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.form-submit {
    border-radius: 15px;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    padding: 20px 0px;
    text-align: center;
    color: #373230;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.form-submit span {
    margin-right: 10px;
}
</style>
