<template>
    <div class="table-row">
      <div class="title">{{ elem.title }}</div>
      <div class="value">{{ elem.value }}</div>
      <CopyButton v-if="elem.copyValue" :textToCopy="elem.copyValue" />
    </div>
  </template>
  
  <script>
  import CopyButton from './CopyButton.vue';
  
  export default {
    name: 'TableElem',
    components: {
      CopyButton
    },
    props: {
      elem: Object
    }
  }
  </script>
  