<template>
    <div class="steps">
      <PaymentStep v-for="step in steps" :key="step.number" :step="step" :payment="payment" />
    </div>
  </template>
  
  <script>
  import PaymentStep from './PaymentStep.vue';
  
  export default {
    name: 'PaymentStepList',
    components: {
      PaymentStep
    },
    props: {
      steps: {
        type: Object,
        required: true
      },
      payment: Object
    },
  }
  </script>

  