<template>
  <div class="rotating-circle"></div>
  <h2 class="mt-20"><b>Ищем платеж</b></h2>
  <p class="mt-10" style="font-size: 16px">Это займет до 5 минут. <br>Пожалуйста, подождите</p>
  <CustomButton component="button" label="Вернуться к оплате" @click="closeModal" class="mt-30 gray small" />
</template>

<script>
import CustomButton from './CustomButton.vue';

export default {
    components: {
        CustomButton
    },
    methods: {
       closeModal() {
         this.$emit('closeModal');
       }
    }

}
</script>

<style>

</style>