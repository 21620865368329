<template>
    <div class="block receipent">
        <div class="title">Получатель</div>
        <div class="value" style="font-size: 16px">{{ payment.source_transaction.requisities.card_holder }}</div>
    </div>

    <!-- Отображение списка шагов на основе payment_method -->
    <PaymentStepList v-if="currentPaymentSteps" :steps="currentPaymentSteps" :payment="payment" />
    <CustomButton label="Я оплатил(-а)" @click="openModal('LookingForPayment'); markPaid();" class="green small"
        style="margin-top: 20px;" :payment="payment" component="button" />

</template>
  
  <script>
  import PaymentStepList from './PaymentStepList.vue';
  import CustomButton from './CustomButton.vue';
  import '../assets/styles/PaymentSpoiler.css';
  
  import formatRequisites from '../assets/utils/formatRequisites.js';
  import formatNumber from '../assets/utils/formatNumber.js';
  
  
  export default {
    name: 'PaymentSpoiler',
    props: {
      payment: Object
    },
    data() {
      return {};
    },
    components: {
      PaymentStepList,
      CustomButton
    },
    computed: {
      requisite () {
        return this.payment.source_transaction.requisities.number
      },
      currentPaymentSteps() {
        const step = this.paymentSteps.find(step => step.type === this.payment.source_transaction.payment_method);
        return this.requisite && step ? step.content : null;
      },
      paymentSteps() {
        return [
          {
            type: "account_number",
            content: [
              { number: 1, text: 'Скопируйте номер счета:', copyValue: this.requisite, formattedValue: formatRequisites(this.requisite, "account_number") },
              { number: 2, text: 'Откройте <span>приложение Сбербанка</span>' },
              { number: 3, text: 'Перейдите в раздел переводов по&nbsp;номеру&nbsp;счета' },
              // { number: 4, text: `Введите сумму <span>строго ${formatNumber(this.payment.fiat_amount)} ₽</span>`, copyValue: this.payment.fiat_amount, formattedValue: formatNumber(this.payment.fiat_amount) + ' ₽' }
              { number: 4, text: `Введите сумму <span>строго ${formatNumber(this.payment.fiat_amount)} ₽</span>`},
              { number: 5, text: 'В назначении платежа укажите <span>«Перевод»</span>' }
  
            ]
          },
          {
            type: "sbp",
            content: [
              { number: 1, text: 'Скопируйте номер телефона:', copyValue: this.requisite, formattedValue: formatRequisites(this.requisite, "sbp") },
              { number: 2, text: 'Откройте <span>приложение банка</span>' },
              { number: 3, text: `Переведите <span>строго ${formatNumber(this.payment.fiat_amount)} ₽</span> по СБП в банк: <span>Сбербанк</span>` }
            ]
          },
          {
            type: "card",
            content: [
              { number: 1, text: 'Скопируйте номер карты:', copyValue: this.requisite, formattedValue: formatRequisites(this.requisite, "card") },
              { number: 2, text: 'Откройте <span>приложение банка</span>' },
              { number: 3, text: 'Переведите <span>строго ${formatNumber(this.payment.fiat_amount)} ₽</span>' }
            ]
          }
        ]
      } 
    },
    methods: {
      markPaid() {
        this.$emit('markPaid')
      },
      openModal() {
        this.$emit('openModal')
      },
      closeModal() {
        this.$emit('closeModal')
      }
    }
  };
  </script>
  
