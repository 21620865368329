<template>
    <component :is="component" :href="href" target="_blank" class="custom-button">
      <template v-if="iconPosition === 'left' && iconType">
        <div :class="['icon', 'icon-left', iconType]"></div>
      </template>
  
      <span>{{ label }}</span>
  
      <template v-if="iconPosition === 'right' && iconType">
        <div :class="['icon', 'icon-right', iconType]"></div>
      </template>
    </component>
  </template>
  
  <script>
  export default {
    name: 'CustomButton',
    props: {
      component: {
        type: String,
        default: 'a'
      },
      href: String,
      label: {
        type: String,
        required: true
      },
      iconType: {
        type: String,
        default: ''
      },
      iconPosition: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'right'].includes(value)
      },
      payment: Object
    },
  }
  </script>
  
  <style>
  .icon {
    width: 18px; 
    height: 18px;
    background-position: center;
    background-size: cover;
  }
  .icon-left {
    margin-right: 10px; 
  }
  .icon-right {
    margin-left: 10px;
  }
  </style>
  